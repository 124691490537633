import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image2 from "../../assets/42.png";
import Image3 from "../../assets/43.png";
import Image4 from "../../assets/45.png";
import Image5 from "../../assets/51.png";
import PlayStore from "../../assets/GoogleInstall.png";
import "./Slick.css";
const Slick = () => {
  var settings = {
    dots: true,
    // infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="slick"
    >
      <div>
        <div
          style={{
            width: "96vw",

            justifyContent: "center",
            display: "flex",
            // flex: 5,
          }}
          className="slick_container_lvl_1"
        >
          <Slider {...settings} className="slickContainer">
            <div className="slickTabs">
              <img
                className="slickImage"
                src="https://res.cloudinary.com/drjnmxyd5/image/upload/v1676035412/40_1_wvub54.svg"
                alt=""
              />
              <h1>UNIQUE STATUS</h1>
              <p>
                Express your feelings to the people through sharing unique
                status from different status categories
              </p>
            </div>
            <div className="slickTabs">
              <img className="slickImage" src={Image4} alt="" />
              <h1>CUSTOMEISED POST AND BUSINESS CARDS</h1>
              <p>
                Design your posts and business cards using unique and easy tools
                and share anywhere
              </p>
            </div>
            <div className="slickTabs">
              <img className="slickImage" src={Image3} alt="" />
              <h1>GROW YOUR BUSINESS</h1>
              <p>
                We will help you to grow your Business by providing digital
                content
              </p>
            </div>
            <div className="slickTabs">
              <img className="slickImage" src={Image5} alt="" />
              <h1>STEGANOGRAPHY</h1>
              <p>
                Share and read secret messages through Images by using our
                unique steganography technology
              </p>
            </div>
            <div className="slickTabs">
              <img className="slickImage" src={Image2} alt="" />
              <h1>REFER AND EARN</h1>
              <p>
                Earn extra income by sharing our app with your family and
                friends
              </p>
            </div>
          </Slider>
        </div>
      </div>
      <div>
        <img src={PlayStore} alt="" className="downloadIcon" />
      </div>
    </div>
  );
};

export default Slick;
