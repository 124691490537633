import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Topbar from "../Topbar/Topbar";
import TermsUI from "../UI/TermsUI";
import "./Privacy.css";
const Privacy = () => {
  return (
    <div className="commonContainer">
      <Topbar />
      <TermsUI text={"PRIVACY POLICY"} />
      <div className="container_lvl_1 terms">
        <div>
          <p>
            Thank you for visiting &ldquo;www.mystatus.store&rdquo; (the
            &ldquo;Site&rdquo;) &amp; &ldquo;My Status Mobile Application&rdquo;
            (the &ldquo;App&rdquo;), the official corporate website and mobile
            application for the MY STATUS respectively. As used in this Privacy
            Policy, the terms &ldquo;our,&rdquo; &ldquo;we&rdquo; and
            &ldquo;us&rdquo; refer to MY STATUS unless the context clearly
            provides otherwise.
          </p>

          <p>
            We appreciate the opportunity to interact with you on the Internet
            and are committed to protecting and safeguarding your privacy. The
            purpose of this Privacy Policy is to inform you about the types of
            information we might collect about you when you visit our Site and
            App, how we may use that information, whether we disclose it to
            anyone, and the choices you have regarding our use of, and your
            ability to correct, that information.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>What Information do we collect:</strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            The information we collect and process about you depends on how you
            use our Products.
          </p>
          <ul>
            <li>Your activity and information you provide:</li>
          </ul>
          <p>
            On our Products, you can send messages, take photos and videos, buy
            or sell things and much more. We call all of the things you can do
            on our Products &quot;activity.&quot; We collect your activity
            across our Products and , information you provide, such as:
          </p>
          <ol>
            <li>&nbsp;you create, Customize posts, images or videos</li>
            <li>
              Content you provide through our camera feature or your camera roll
              settings
            </li>
            <li>
              Messages you send and receive, including their content, subject to
              applicable law.
            </li>
            <li>
              Types of content you view or interact with, and how you interact
              with it
            </li>
            <li>
              Apps and features you use, and what actions you take in them.
            </li>
            <li>
              Purchases or other transactions you make, including credit card
              information.
            </li>
            <li>
              The time, frequency and duration of your activities on our
              Products
            </li>
          </ol>

          <ul>
            <li>Friends, followers and other connections:</li>
          </ul>
          <ol>
            <li>
              &nbsp;collect information about friends, followers, groups
              you&rsquo;re connected to and interact with. This includes how you
              interact with them across our services and which ones you interact
              with the most.
            </li>
            <li>
              We also collect your contacts&rsquo; information, such as their
              name and email address or phone number, if you choose to upload or
              import it from a device, like by syncing an address book.
            </li>
          </ol>

          <ul>
            <li>App, browser and device information:</li>
          </ul>
          <p>
            We collect and receive information from and about the
            different&nbsp;&nbsp;you use and how you use them.
          </p>
          <p>Device information we collect and receive includes:</p>
          <ol>
            <li>
              &nbsp;device and software you&rsquo;re using, and other device
              characteristics.
            </li>
            <li>
              What you&rsquo;re doing on your device, like whether our app is in
              the foreground or if your mouse is moving (which can help tell
              humans from bots)
            </li>
            <li>
              Identifiers that tell your device apart from other users&rsquo;,
              including Family Device IDs.
            </li>
            <li>Signals from your device.</li>
            <li>
              Information you&rsquo;ve shared with us through device settings,
              like GPS location, camera access, photos etc.
            </li>
            <li>
              Information about the network you connect your device to,
              including your IP address.
            </li>
            <li>
              Information about our Products&rsquo; performance on your device.
            </li>
            <li>Information from cookies and similar technologies.</li>
          </ol>

          <ul>
            <li>Information from Partners, vendors and third parties:</li>
          </ul>
          <p>
            We collect and receive information from Partners, vendors and third
            parties about a variety of your information and activities on and
            off our Products.
          </p>
          <p>Here are some examples of information we receive about you:</p>
          <ol>
            <li>&nbsp;device information</li>
            <li>
              Websites you visit and cookie data, like through Social Plugins
            </li>
            <li>Apps you use</li>
            <li>Games you play</li>
            <li>Purchases and transactions you make</li>
            <li>Your demographics, like your education level</li>
            <li>he ads you see and how you interact with them</li>
            <li>How you use our products and services, online or in person</li>
          </ol>

          <p>
            <br />
          </p>
          <p>
            <strong>How do we use your information?</strong>
          </p>
          <p>
            <br />
          </p>

          <p>
            We use&nbsp;&nbsp;information we collect to provide a personalized
            experience to you, including ads, along with the other purposes we
            explain in detail below.
          </p>
          <ul>
            <li>How we provide, personalize and improve our Products:</li>
          </ul>
          <ol>
            <li>
              &nbsp;use information we have to provide and improve our Products.
            </li>
            <li>
              This includes personalizing features, content and recommendations.
            </li>
          </ol>

          <ul>
            <li>How we show ads and other sponsored or commercial content:</li>
          </ul>
          <p>
            To decide what to show you and others, we use information we have
            about you, including:
          </p>
          <ol>
            <li>&nbsp;profile information</li>
            <li>
              Your activity on and off our Products, including information we
              receive through cookies and similar technologies.
            </li>
            <li>
              Things we infer about you, like topics we think you may be
              interested in
            </li>
            <li>
              Information about your friends, followers or other connections,
              including their activity or interests
            </li>
          </ol>

          <ul>
            <li>How we use information to improve our Products :</li>
          </ul>
          <p>
            We&rsquo;re always trying to make our Products better and create new
            ones with the features you want. Information we collect from you
            helps us learn how.
          </p>
          <p>We use information we collect to:</p>
          <ol>
            <li>&nbsp;if a product is working correctly</li>
            <li>Troubleshoot and fix it when it&rsquo;s not</li>
            <li>Test out new products and features to see if they work</li>
            <li>Get feedback on our ideas for products or features</li>
            <li>
              Conduct surveys and other research about what you like about our
              Products and brands and what we can do better
            </li>
          </ol>

          <ul>
            <li>How we use location-related information:</li>
          </ul>
          <p>
            We use location-related information that you allow us to receive if
            you turn on the Location Services device setting. This includes
            things like your GPS location and, depending on the operating system
            you&apos;re using.
          </p>
          <p>
            &nbsp; &nbsp; We also receive and use some location related
            information even if Location Services is turned off. This includes:
          </p>
          <ol>
            <li>
              &nbsp;addresses, which we use to estimate your general location.
              We can use IP addresses to estimate your specific location if
              it&rsquo;s necessary to protect the safety and security of you or
              others.
            </li>
            <li>
              Your and other&rsquo;s activity on our Products, like check-ins
              and events
            </li>
            <li>
              Information you give us directly, like if you enter your current
              city on your profile, or provide your address in Marketplace
            </li>
          </ol>

          <p>
            We use location-related information, such as your current location,
            where you live, the places you like to go and the businesses and
            people you&apos;re near, to do the things, like:
          </p>
          <ol>
            <li>
              Provide, personalize and improve our Products, including ads, for
              you and others.
            </li>
            <li>
              &nbsp;suspicious activity and help keep your account secure.
            </li>
          </ol>

          <p>
            <br />
          </p>
          <p>
            <strong>How long do we keep your information?</strong>
          </p>
          <p>
            <br />
          </p>

          <p>
            We keep information as long as we need it to provide our Products,
            comply with legal obligations or protect our or other&rsquo;s
            interests. We decide how long we need information on a case-by-case
            basis. Here&rsquo;s what we consider when we decide:
          </p>
          <ol>
            <li>
              &nbsp;we need it to operate or provide our Products. For example,
              we need to keep some of your information to maintain your account.
            </li>
            <li>The feature we use it for, and how that feature works.</li>
            <li>
              How long we need to retain the information to comply with certain
              legal obligations.
            </li>
            <li>
              If we need it for other legitimate purposes, such as to prevent
              harm; investigate possible violations of our terms or policies;
              promote safety, security and integrity; or protect ourselves,
              including our rights, property or products
            </li>
            <li>
              In some instances and for specific reasons, we&rsquo;ll keep
              information for an extended period of time.
            </li>
          </ol>

          <p>
            <br />
          </p>
          <p>
            <strong>Use of Third-Party Media and Research Companies</strong>
          </p>
          <p>
            <br />
          </p>

          <p>
            Our Site and App may run limited third-party ads only for those
            Merchant and Services Partners with whom we link. In addition, we
            may use third-party media and research companies to place ads for us
            on other parties&rsquo; Web sites.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>Links To Other Web Sites And Apps</strong>
          </p>
          <p>
            <br />
          </p>

          <p>
            Links to third-party Web sites and Apps may be provided solely for
            your information and convenience, or to provide additional shopping
            for various other goods and services through our Merchant and
            Services Partners. If you use these links, you will leave our Site.
            This Privacy Policy does not cover the information practices of
            those Web sites linked to our Site and App, nor do we control their
            content or privacy policies. We suggest that you carefully review
            the privacy policies of each site and app you visit.
          </p>

          <p>
            We maintain contractual agreements with all of our Merchant and
            Services Partners, which require them to observe the intent of this
            Privacy Policy. When you enter a Partner Store from our Site and
            App, we provide them only with your user identification (UID) number
            for purposes of introducing you. In return, the Partner Store shares
            with us any purchases you make from their Web sites and apps in
            order for us to assign proper credit to you and to your servicing
            MSBO.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>Children&rsquo;s Privacy Protection</strong>
          </p>
          <p>
            <br />
          </p>

          <p>
            We take special care to protect the privacy needs of children and
            encourage parents to be an active participant in their child&rsquo;s
            online activities. Our Site and App does not target and is not
            intended for children under the age of 18, and we will not knowingly
            collect Personally Identifiable Information from them. If we
            discover personal data from a child through our Site and App, we
            will eliminate that data.&nbsp;&nbsp;You must be at least 18 years
            old as a customer or qualify as an MSBO to be on our Site and App.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>Changes To This Policy</strong>
          </p>
          <p>
            <br />
          </p>

          <p>
            Any updates or changes to the terms of this Privacy Policy will be
            posted here on our Site, App and the date of the newest version
            posted below. Please check back frequently, especially before you
            submit any Personally Identifiable Information at our Site and App,
            to see if this Privacy Policy has changed. If any changes materially
            affect the use of Personally Identifiable Information previously
            provided to us, we will obtain your consent prior to the retroactive
            application of any material changes. By using our Site, you
            acknowledge acceptance of this Privacy Policy in effect at the time
            of use.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>
            <br />
          </p>

          <p>
            If you have any concerns about our use of your information or about
            this Privacy Policy, please send an e-mail{" "}
            <a href="mailto:feedback@mystatus.store">feedback@mystatus.store</a>{" "}
            . We will make every reasonable effort to address your concerns and
            remedy any problems you bring to our attention.
          </p>

          <p className="terms">
            Updated and effective from 26<sup>th</sup> Jan 2023.
            <br />
            Copyright &copy;2023 MY STATUS.
            <br />
            All rights reserved.
          </p>

          {/* <div data-include="/Privacy-Policy.html"></div> */}
          {/* <p>
            <h1 className="heading">
              Version <br /> 1.0.0
            </h1>
            <h1 className="heading">
              AUTHOR{" "}
              <span>
                <p>Team My Status</p>
              </span>
            </h1>
            <h1 className="heading">
              Developed by
              <span>
                <p>My Software Solution</p>
              </span>
            </h1>
            <p style={{ marginBottom: "20px" }}>
              We’re client-focused application designers{" "}
            </p>
            <p style={{ marginBottom: "20px" }}>
              {" "}
              Our master group of application designers utilizes more
              intelligent and friendlier routes for our customers and their
              clients to draw in with innovation, making remarkable client
              encounters.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We don’t make do with OK, we consistently make progress toward
              magnificence. It’s this enthusiasm that enables our customers to
              change transformation rates, enhance mark notorieties, win grants
              and remain on top of things, after quite a long time.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              We pride ourselves on building long-haul associations with our
              customers, attempting to enable them to accomplish their business
              objectives. You’ll work specifically with the application
              engineers themselves, auditing and teaming up with them on each
              phase of the procedure.
            </p>
            <h1 className="heading"> .</h1>
            <h1 className="heading">HOW DO WE DO IT?</h1>
            <p style={{ marginBottom: "20px" }}>
              We help control customers through the procedures of building an
              application, from starting a plan to supporting the last
              organization.
            </p>
            <h1 className="heading">Pre-Design</h1>
            <p style={{ marginBottom: "20px" }}>
              We break down the prerequisites of the venture so as to prescribe
              the best way to deal with meet both business and client
              objectives. We don’t just take briefs; we challenge any
              pre-characterized usefulness or choices and talk about choices
              using our profound versatile information.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Brushing your industry information with our versatile innovation
              skills.
            </p>
            <h1 className="heading">Plan</h1>
            <p style={{ marginBottom: "20px" }}>
              We draw on our broad experience, got from planning more than 250
              versatile arrangements, so as to enable customers to structure a
              five-star client encounter.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Our creative plans will guarantee that the UX of your application
              has your end client at the cutting edge of each choice. With
              client testing and criticism at all times, ready to plan the ideal
              answer for your intended interest group.
            </p>
            <h1 className="heading">Improvement</h1>
            <p style={{ marginBottom: "20px" }}>
              Following the light-footed technique, we can do nonstop discharges
              to give you full perceivability of the venture as it advances. Our
              communitarian approach will offer you the chance to criticize
              toward the finish of each run.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Working inside test-driven conditions implies we can guarantee
              well-performing and stable forms all through improvement.
            </p>
            <h1 className="heading">24X7 Support</h1>
            <p style={{ marginBottom: "20px" }}>
              We are truly available 24X7 to support our clients When an
              application is propelled, we screen it intimately with our
              in-constructed investigation to keep up and enhance the item as we
              travel through the future stages.
            </p>
            <p style={{ marginBottom: "20px" }}>
              We pride ourselves on proactively breaking down the information,
              which enables us to prescribe extra usefulness or changes,
              inserting the application inside the intended interest group, and
              increasing significant direct criticism.
            </p>
          </p> */}
        </div>
      </div>
      <div className="ugcFooter">
        <Footer />
      </div>
    </div>
    // <div className="privacyContainer">
    //   <Topbar />
    //   <Header />
    //   <div className="privacydetailsContainer">
    //     <h2>PRIVACY POLICY</h2>
    //     <p>
    //       Thank you for visiting “www.mystatus.store” (the “Site”) & “My Status
    //       Mobile Application” (the “App”), the official corporate website and
    //       mobile application for the MY STATUS respectively. As used in this
    //       Privacy Policy, the terms “our,” “we” and “us” refer to MY STATUS
    //       unless the context clearly provides otherwise.
    //     </p>
    //     <p>
    //       We appreciate the opportunity to interact with you on the Internet and
    //       are committed to protecting and safeguarding your privacy. The purpose
    //       of this Privacy Policy is to inform you about the types of information
    //       we might collect about you when you visit our Site and App, how we may
    //       use that information, whether we disclose it to anyone, and the
    //       choices you have regarding our use of, and your ability to correct,
    //       that information.
    //     </p>
    //     <h3>What Information do we collect:</h3>
    //     <p>
    //       The information we collect and process about you depends on how you
    //       use our Products.
    //     </p>
    //     <ul style={{ marginLeft: "10px" }}>
    //       <li>Your activity and information you provide:</li>
    //       <p>
    //         On our Products, you can send messages, take photos and videos, buy
    //         or sell things and much more. We call all of the things you can do
    //         on our Products "activity." We collect your activity across our
    //         Products and , information you provide, such as:
    //       </p>
    //       <div style={{ marginLeft: "80px" }}>
    //         <li> Content you create, Customize posts, images or videos</li>
    //         <li>
    //            Content you provide through our camera feature or your camera
    //           roll settings
    //         </li>
    //         <li>
    //            Messages you send and receive, including their content, subject
    //           to applicable law.
    //         </li>
    //         <li>
    //            Types of content you view or interact with, and how you interact
    //           with it{" "}
    //         </li>
    //         <li>
    //            Apps and features you use, and what actions you take in them.
    //         </li>{" "}
    //         <li>
    //            Purchases or other transactions you make, including credit card
    //           information.
    //         </li>
    //         <li>
    //            The time, frequency and duration of your activities on our
    //           Products
    //         </li>
    //       </div>
    //       <li>Friends, followers and other connections:</li>
    //       <div style={{ marginLeft: "80px" }}>
    //         <li>
    //            We collect information about friends, followers, groups you’re
    //           connected to and interact with. This includes how you interact
    //           with them across our services and which ones you interact with the
    //           most.
    //         </li>
    //         <li>
    //            We also collect your contacts’ information, such as their name
    //           and email address or phone number, if you choose to upload or
    //           import it from a device, like by syncing an address book
    //         </li>
    //       </div>
    //       • App, browser and device information:
    //       <br /> We collect and receive information from and about the different
    //       you use and how you use them.
    //       <br />
    //       Device information we collect and receive includes:{" "}
    //       <div style={{ marginLeft: "80px" }}>
    //         <li>
    //            The device and software you’re using, and other device
    //           characteristics.
    //         </li>
    //         <li>
    //            What you’re doing on your device, like whether our app is in the
    //           foreground or if your mouse is moving (which can help tell humans
    //           from bots){" "}
    //         </li>{" "}
    //         <li>
    //            Identifiers that tell your device apart from other users’,
    //           including Family Device IDs.  Signals from your device.
    //         </li>
    //         <li>
    //            Information you’ve shared with us through device settings, like
    //           GPS location, camera access, photos etc.
    //         </li>{" "}
    //         <li>
    //            Information about the network you connect your device to,
    //           including your IP address.
    //         </li>
    //         <li>
    //            Information about our Products’ performance on your device.
    //         </li>{" "}
    //         <li> Information from cookies and similar technologies.</li>
    //       </div>{" "}
    //       • Information from Partners, vendors and third parties:
    //       <br /> We collect and receive information from Partners, vendors and
    //       third parties about a variety of your information and activities on
    //       and off our Products.
    //       <br />
    //       Here are some examples of information we receive about you:{" "}
    //       <div style={{ marginLeft: "80px" }}>
    //         <li> Your device information</li>{" "}
    //         <li>
    //            Websites you visit and cookie data, like through Social Plugins
    //         </li>{" "}
    //         <li> Apps you use</li> <li> Games you play</li>{" "}
    //         <li> Purchases and transactions you make</li>{" "}
    //         <li> Your demographics, like your education level</li>
    //         <li> he ads you see and how you interact with them</li>{" "}
    //         <li>
    //            How you use our products and services, online or in person
    //         </li>
    //       </div>
    //       <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
    //         How do we use your information?
    //       </h3>
    //       <li>
    //         We use information we collect to provide a personalized experience
    //         to you, including ads, along with the other purposes we explain in
    //         detail below. <br />• How we provide, personalize and improve our
    //         Products:{" "}
    //         <div style={{ marginLeft: "80px" }}>
    //           <li>
    //              We use information we have to provide and improve our
    //             Products.
    //           </li>
    //           <li>
    //              This includes personalizing features, content and
    //             recommendations.
    //           </li>{" "}
    //         </div>
    //         • How we show ads and other sponsored or commercial content: <br />
    //         To decide what to show you and others, we use information we have
    //         about you, including:
    //         <div style={{ marginLeft: "80px" }}>
    //           <li> Your profile information</li>{" "}
    //           <li>
    //              Your activity on and off our Products, including information
    //             we receive through cookies and similar technologies.
    //           </li>{" "}
    //           <li>
    //             {" "}
    //              Things we infer about you, like topics we think you may be
    //             interested in
    //           </li>
    //           <li>
    //             {" "}
    //              Information about your friends, followers or other
    //             connections, including their activity or interests
    //           </li>
    //         </div>
    //         • How we use information to improve our Products :<br /> We’re
    //         always trying to make our Products better and create new ones with
    //         the features you want. Information we collect from you helps us
    //         learn how.
    //         <br />
    //         We use information we collect to:
    //         <br />
    //         <div style={{ marginLeft: "80px" }}>
    //           <li>  See if a product is working correctly</li>
    //           <li>  Troubleshoot and fix it when it’s not</li>
    //           <li>
    //              Test out new products and features to see if they work
    //           </li>{" "}
    //           <li> Get feedback on our ideas for products or features</li>
    //           <li>
    //              Conduct surveys and other research about what you like about
    //             our Products and brands and what we can do better
    //           </li>
    //         </div>
    //         • How we use location-related information:
    //         <br /> We use location-related information that you allow us to
    //         receive if you turn on the Location Services device setting. This
    //         includes things like your GPS location and, depending on the
    //         operating system you're using.
    //         <br /> We also receive and use some location related information
    //         even if Location Services is turned off. This includes:
    //         <br />{" "}
    //         <div style={{ marginLeft: "80px" }}>
    //           <li>
    //              IP addresses, which we use to estimate your general location.
    //             We can use IP addresses to estimate your specific location if
    //             it’s necessary to protect the safety and security of you or
    //             others.
    //           </li>
    //           <li>
    //              Your and other’s activity on our Products, like check-ins and
    //             events
    //           </li>{" "}
    //           <li>
    //              Information you give us directly, like if you enter your
    //             current city on your profile, or provide your address in
    //             Marketplace
    //           </li>{" "}
    //         </div>
    //         We use location-related information, such as your current location,
    //         where you live, the places you like to go and the businesses and
    //         people you're near, to do the things, like:
    //         <br />
    //         <div style={{ marginLeft: "80px" }}>
    //           <li>
    //              Provide, personalize and improve our Products, including ads,
    //             for you and others.
    //           </li>
    //           <li>
    //              Detect suspicious activity and help keep your account secure.
    //           </li>
    //         </div>
    //       </li>
    //       <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
    //         How long do we keep your information?
    //       </h3>
    //       <li>
    //         We keep information as long as we need it to provide our Products,
    //         comply with legal obligations or protect our or other’s interests.
    //         We decide how long we need information on a case-by-case basis.
    //         Here’s what we consider when we decide: <br />
    //         <div style={{ marginLeft: "80px" }}>
    //           <li>
    //              If we need it to operate or provide our Products. For example,
    //             we need to keep some of your information to maintain your
    //             account.
    //           </li>{" "}
    //           <li>
    //              The feature we use it for, and how that feature works.  How
    //             long we need to retain the information to comply with certain
    //             legal obligations.
    //           </li>{" "}
    //           <li>
    //              If we need it for other legitimate purposes, such as to
    //             prevent harm; investigate possible violations of our terms or
    //             policies; promote safety, security and integrity; or protect
    //             ourselves, including our rights, property or products
    //           </li>{" "}
    //           <li>
    //              In some instances and for specific reasons, we’ll keep
    //             information for an extended period of time.
    //           </li>
    //         </div>
    //       </li>
    //       <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
    //         Use of Third-Party Media and Research Companies
    //       </h3>
    //       <p>
    //         Our Site and App may run limited third-party ads only for those
    //         Merchant and Services Partners with whom we link. In addition, we
    //         may use third-party media and research companies to place ads for us
    //         on other parties’ Web sites.
    //       </p>
    //       <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
    //         Links To Other Web Sites And Apps
    //       </h3>
    //       <p>
    //         Links to third-party Web sites and Apps may be provided solely for
    //         your information and convenience, or to provide additional shopping
    //         for various other goods and services through our Merchant and
    //         Services Partners. If you use these links, you will leave our Site.
    //         This Privacy Policy does not cover the information practices of
    //         those Web sites linked to our Site and App, nor do we control their
    //         content or privacy policies. We suggest that you carefully review
    //         the privacy policies of each site and app you visit.
    //       </p>
    //       <p>
    //         We maintain contractual agreements with all of our Merchant and
    //         Services Partners, which require them to observe the intent of this
    //         Privacy Policy. When you enter a Partner Store from our Site and
    //         App, we provide them only with your user identification (UID) number
    //         for purposes of introducing you. In return, the Partner Store shares
    //         with us any purchases you make from their Web sites and apps in
    //         order for us to assign proper credit to you and to your servicing
    //         MSBO.
    //       </p>
    //       <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
    //         Children’s Privacy Protection
    //       </h3>
    //       <p>
    //         We take special care to protect the privacy needs of children and
    //         encourage parents to be an active participant in their child’s
    //         online activities. Our Site and App does not target and is not
    //         intended for children under the age of 18, and we will not knowingly
    //         collect Personally Identifiable Information from them. If we
    //         discover personal data from a child through our Site and App, we
    //         will eliminate that data. You must be at least 18 years old as a
    //         customer or qualify as an MSBO to be on our Site and App.
    //       </p>
    //       <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
    //         Changes To This Policy
    //       </h3>
    //       <p>
    //         Any updates or changes to the terms of this Privacy Policy will be
    //         posted here on our Site, App and the date of the newest version
    //         posted below. Please check back frequently, especially before you
    //         submit any Personally Identifiable Information at our Site and App,
    //         to see if this Privacy Policy has changed. If any changes materially
    //         affect the use of Personally Identifiable Information previously
    //         provided to us, we will obtain your consent prior to the retroactive
    //         application of any material changes. By using our Site, you
    //         acknowledge acceptance of this Privacy Policy in effect at the time
    //         of use.
    //       </p>
    //       <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
    //         Contact Us
    //       </h3>
    //       <p>
    //         If you have any concerns about our use of your information or about
    //         this Privacy Policy, please send an e-mail feedback@mystatus.store .
    //         We will make every reasonable effort to address your concerns and
    //         remedy any problems you bring to our attention.{" "}
    //       </p>
    //       <p>
    //         Updated and effective from 26th Jan 2023.
    //         <br /> ©2023 MY STATUS, all rights reserve
    //       </p>
    //     </ul>
    //   </div>
    // </div>
  );
};

export default Privacy;
