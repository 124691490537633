import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Topbar from "../Topbar/Topbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./UgcTerms.css";
import { Link } from "react-router-dom";
import "../MobileAboutus/MobileAboutus.css";
import TermsUI from "../UI/TermsUI";
import Section from "../UI/ArticleSection";

const UgcTerms = () => {
  const [open, setopen] = useState(false);
  const openD = () => {
    setopen(!open);
  };
  return (
    <div className="commonContainer">
      <Topbar />
      <TermsUI text={"UGC TERMS"} />
      <div className="container_lvl_1 terms">
        <div>
          <Section
            heading={"USER GENERATED CONTENT AGREEMENT"}
            paragraphs={[
              <p>
                This User Generated Content Agreement (the “Agreement”) seeks
                your permission to use and showcase your social media content
                generated by you on our website, mobile application, social
                media sites, and promotional/informational materials (“User
                Content”). BY SUBMITTING “MY STATUS” APPLICATION FORM OR
                REGISTER YOURSELF IN “MY STATUS” APPLICATION – YOU AGREE AND
                ACCEPT THE TERMS OF THIS AGREEMENT, ENTERED BY AND BETWEEN YOU,
                THE OWNER OF THE USER CONTENT, AND MY STATUS (“Company”). For
                good and valuable consideration, of which the parties
                acknowledge, the terms of this Agreement are as follows:
              </p>,
              <p>
                You hereby grant to “MY STATUS” and its related companies,
                agents, licensees, sublicensees, contractors, successors,
                assignees, third-party service providers, and other affiliates
                (collectively “Licensees”), in accordance with the terms and
                conditions of this Agreement, a worldwide, perpetual,
                irrevocable, royalty-free, fully-paid, non-exclusive, and
                transferrable license to use, display, reproduce, distribute,
                transmit, create derivative works from, and alter, your User
                Content for any purpose, in any manner to be determined by
                Licensees in their sole discretion, including but not limited to
                on webpages and social media pages, in promotional e-mails and
                advertisements, and in any and all other marketing, promotional
                and advertising initiatives, and in any type or form of media
                now or hereafter known. The Licensees may use, display,
                reproduce, distribute, transmit, create derivative works from,
                combine with other materials, alter and/or edit User Content in
                any manner in their sole discretion, with no obligation to you
                whatsoever. You further grant to the Licensees a worldwide,
                perpetual, irrevocable, royalty-free, fully-paid, non-exclusive,
                and transferrable license to use, display, reproduce,
                distribute, transmit, create derivative works from, and alter
                your image, voice, name, likeness, signature, business name,
                business logos, and testimonial copy and any other indicia of
                identity (collectively, “Persona”) as contained in the User
                Content, and to make any changes and/or additions thereto, or
                derivative works therefrom. You also grant to Licensees the
                right to use your Persona in association with the User Content.
              </p>,
              <p>
                You represent and warrant that (a) you own all rights in and to
                the User Content, and have the exclusive right to license to
                others the right to produce, copy, make, sublicense or sell the
                User Content; (b) you are not a minor and are of a legal age to
                enter into this Agreement; (c) the User Content, and Licensees
                use of the User Content, does not violate any laws or
                regulations; (d) the User Content is not libelous, defamatory,
                obscene, pornographic, abusive, indecent, threatening,
                harassing, hateful, or offensive or otherwise unlawful; (e) you
                have not used a false email address, impersonated any person or
                entity, or otherwise mislead Licensees as to the origin of any
                User Content; and (f) if you are an MY STATUS Independent
                Business Owner, the User Content complies with the MY STATUS
                Rules of Conduct.
              </p>,
              <p>
                MY STATUS acknowledges and agrees that the license granted
                herein is non-exclusive and that you may license others to use
                the User Content. MY STATUS acknowledges that you are the sole
                and exclusive owner of the Content, and MY STATUS shall do
                nothing inconsistent with such ownership. MY STATUS further
                agrees that it will not claim ownership rights to the User
                Content, or any derivative, compilation, sequel or series, or
                related content owned by or used by you. MY STATUS agrees that
                nothing in this Agreement shall give MY STATUS any right, title,
                or interest in the User Content except as set forth in the terms
                of this Agreement.
              </p>,
              <p>
                The User Content is not confidential to Licensees and shall have
                no protections of confidentiality. By agreeing to this
                Agreement, you consent to Licensees’ collection and use of any
                personal information you provide in connection with Licensees’
                use of the User Content. You acknowledge that your personal
                information may be transferred to servers located outside the
                country in which you live or to third parties in other countries
                so that they may process personal information on behalf of the
                Licensees.
              </p>,
              <p>
                Websites, mobile applications or social media sites owned or
                operated by Licensees may be protected by copyright, trademark
                and other intellectual property laws. You acknowledge and agree
                that you do not acquire any ownership or other rights in
                proprietary information and materials of Licensees by
                authorizing use of the User Content or otherwise using or
                accessing websites, applications or social media sites owned or
                operated by Licensees.
              </p>,
              <p>
                YOU RELEASE, DISCHARGE AND AGREE TO HOLD LICENSEES, AND ANY
                PERSON ACTING ON THEIR BEHALF, HARMLESS FROM ANY LIABILITY
                RELATED IN ANY WAY TO LICENSEES’ USE OF THE USER CONTENT. IN NO
                EVENT WILL LICENSEES BE LIABLE, NOR DO LICESEES ASSUME
                RESPONSIBILITY, FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                THE USE OF THE USER CONTENT, OR OTHERWISE, EVEN IF LICENSEES ARE
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF, NOTWITHSTANDING
                THE OTHER PROVISIONS OF THIS AGREEMENT, LICENSEES ARE FOUND TO
                BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH ARISES OUT OF OR
                IS IN ANY WAY CONNECTED WITH YOUR USE OF THE SITE OR ANY
                CONTENT, LICENESEES’ LIABILITY SHALL IN NO EVENT EXCEED INR
                1,000.00/-. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS
                OF LIABILITY, SO THE FOREGOING LIMITATION MAY NOT APPLY TO YOU.
              </p>,
              <p>
                YOU SPECIFICALLY WAIVE AND SURRENDER ANY CLAIMS WHICH YOU MAY
                NOW HAVE OR HEREAFTER HAVE THAT USE OF YOUR PERSONA IN
                CONNECTION WITH THE USER CONTENT WOULD CONSTITUTE AN INVASION OF
                YOUR PRIVACY AND/OR A VIOLATION OF YOUR RIGHT OF PUBLICITY. YOU
                ALSO SPECIFICALLY WAIVE ANY MORAL RIGHTS OR DROIT MORAL YOU MAY
                HAVE UNDER THE LAWS OF ANY COUNTRY OR STATE, IF PERMITTED UNDER
                THE LAW OF SAID COUNTRY OR STATE. WHERE MORAL RIGHTS CANNOT BE
                WAIVED ACCORDING TO THE APPLICABLE LAW, YOU FURTHER AGREE NOT TO
                EXERCISE SUCH RIGHTS WITH RESPECT FOR THE NORMAL EXPLOITATION OF
                THE USER CONTENT BY LICENSEES.
              </p>,
              <p>
                MY STATUS reserves the right to alter this Agreement without
                advance notice by posting a revised Agreement. Accordingly, you
                should review this Agreement each time you grant permission or
                authorization to use User Content.
              </p>,
              <p>
                No waiver by MY STATUS of any term or condition set out in this
                Agreement shall be deemed a further or continuing waiver of such
                term or condition or a waiver of any other term or condition,
                and any failure of Amway to assert a right or provision under
                these Terms shall not constitute a waiver of such right or
                provision. All disputes arising from the terms of this Agreement
                shall be subjected to binding arbitration with one arbitrator.
                This Agreement shall be governed by and construed in accordance
                with the laws of INDIA without regard to the conflicts of laws
                rules thereof and any arbitration shall be brought in Mumbai
                (INDIA) using INDIAN state laws when applicable.
              </p>,
              <p>
                Updated and effective from 26th Jan 2023.
                <br /> Copyright ©2023, MY STATUS. <br />
                All rights reserved.
              </p>,
            ]}
          />
        </div>
      </div>
      <div className="ugcFooter">
        <Footer />
      </div>
    </div>
  );
};

export default UgcTerms;
