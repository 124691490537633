import React from "react";
// import Plan from "../../assets/plan 2.svg";
import { Link } from "react-router-dom";
import Topbar from "../Topbar/Topbar";
import "./PlanMobileContainer.css";
import Footer from "../Footer/Footer";
import Anim from "../../assets/Cancel_anim.gif";
import Offer from "../../assets/Special_offer.gif";
import PriceBG from "../../assets/Price_BG_new.png";
import GooglePlay from "../../assets/GoogleInstall.png";
import Login from "../../assets/Login 2.svg";
const PlanMobileContainer = () => {
  return (
    <div className="commonContainer">
      <Topbar />
      <div className="pageHeader">
        <Link to="/navmenu">
          <img
            src="https://res.cloudinary.com/drjnmxyd5/image/upload/v1676097641/Group_1602_prpzew.svg"
            alt=""
            className="arrowlogo"
            style={{
              position: "absolute",
              top: "40px",
              right: "0",
              left: "15px",
            }}
          />
        </Link>
        <div className="logoContainer">
          <Link to="/plan">
            <img src={Login} alt="plan" className="logoImages" />
            <p className="navNames">Plan</p>
          </Link>
        </div>
      </div>
      <div className="priceDetails">
        <img
          src={Anim}
          alt="Computer man"
          style={{
            width: "150px",
            height: "150px",
            position: "absolute",
            opacity: 0.5,
          }}
        />

        <p>One Year Subscription @</p>
        <h1>INR 1,999 /-</h1>
        <p>(Inclusive of All Taxes)</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "180px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // position: "absolute",
          }}
        >
          <div style={{ marginRight: "-60px", zIndex: 10 }}>
            <img
              className="OfferImage"
              src={Offer}
              alt=""
              style={{
                width: "150px",
                height: "150px",
              }}
            />
          </div>
          <div>
            <img
              className="OfferImage"
              src={PriceBG}
              style={{
                width: "300px",
                height: "100px",
              }}
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className="playStorePlanContainer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div>
          <img
            src={GooglePlay}
            style={{
              width: "250px",
              height: "90px",
            }}
            alt=""
          />
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default PlanMobileContainer;
