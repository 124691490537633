import React from "react";

const Section = ({ heading, paragraphs }) => {
  return (
    <div style={{ marginBottom: "50px" }}>
      <h1 className="heading" style={{ marginBottom: "20px" }}>
        {heading}
      </h1>
      {paragraphs.map((para) => (
        <>
          <p style={{ marginBottom: "20px" }}>{para}</p>
          <p>
            <br />
          </p>
        </>
      ))}
    </div>
  );
};

export default Section;
