import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/GIF_Color_Logo.gif";

const TermsUI = ({ text }) => {
  return (
    <div className="loginPageHeader">
      <Link to="/navmenu">
        <img
          src={Logo}
          alt=""
          style={{
            position: "absolute",
            top: "15px",
            right: "0",
            left: "15px",
            height: "60px",
            width: "44.64px",
          }}
        />
      </Link>
      <h1
        className="ugcHeading"
        style={{
          marginTop: "3vh",
          fontFamily: "Poppins",
          fontSize: "32px",
          fontWeight: "600",
          lineHeight: "48px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#000000",
          marginBottom: "20px",
          paddingBottom: "1vh",
          opacity: 0.7,
        }}
      >
        {text}
      </h1>
    </div>
  );
};

export default TermsUI;
