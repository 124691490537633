import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Topbar from "../Topbar/Topbar";
import TermsUI from "../UI/TermsUI";
import "./Refferal.css";
const Refferal = () => {
  return (
    <div className="commonContainer">
      {" "}
      <Topbar /> <TermsUI text={"REFERRAL POLICY"} />{" "}
      <div className="container_lvl_1 terms ref">
        {" "}
        <div>
          <p>
            REFERRAL BUSINESS POLICY as mentioned below constitute an agreement
            between &ldquo;MY STATUS&rdquo; (here in after referred to as
            Company) and its Associates/MSBO&rsquo;s (My Status Business Owners)
            (here in after referred to as &ldquo;MY STATUS&rdquo;
            distributors/agents one who duly purchased the mobile app, products,
            package of the company and completes all the formalities laid down
            by the company.)
          </p>
          <p>
            This agreement does not create an employee-employer relationship,
            partnership or joint venture between &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s and Company. The &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s must be of legal age 18 years as on the date
            of application/registration. The &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s entering into this agreement with his free
            will and consent. Company does not promise any kind of monitory gain
            in any way to &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s.
            Earning Commission or Get Gifts from &ldquo;MY STATUS&rdquo;,
            Associates/MSBO&rsquo;s will be solely depend on his/her best
            performance. All the &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s
            will use his/her best effort to permute mobile app subscription,
            product package and services of the company truthful and honest
            manner. The &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s should
            not use trademarked symbol literature and slogans of the company for
            any other purpose. Other then as specified by the company. The
            company will deduct tax at source as per income tax act 1961. As
            well as any other Govt. taxes as applicable time to
            time/duties/charges as the prevailing rate, an annual processing
            registration and Service charges etc.
          </p>
          <p>
            <strong>REGISTRATION</strong>
          </p>
          <p>
            The &ldquo;MY STATUS&rdquo; mobile app subscription, product package
            sale or referral bonus for to people residing in India and for
            Indian nationals only. The Registration of &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s becomes ACTIVE USER effective from the date
            of acceptance of agreement valid up to one year against Full payment
            of the subscription/purchase of product package to the company.{" "}
            <strong>
              (Note : Registration for REFERRAL BUSINESS is free for all users)
            </strong>
          </p>
          <p>
            <strong>FEES&nbsp;</strong>
          </p>
          <p>
            The amount of purchase of mobile subscription, product package or
            registration processing charge of company and rights to become
            &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s of the company. The
            amount paid towards company for the purchase of the mobile
            subscription, product package or registration processing charge is
            non refundable and non transferable. Courier/product delivery
            charges/octopi/local taxes any other Govt. taxes applicable time to
            time should strictly be born by &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s only. Monthly statement or any business
            related document of &ldquo;MY STATUS&rdquo; Associate&rsquo;s,
            incentive, cheque, gifts will be made chartable basis which includes
            the actual cost of communication and delivery charges.
          </p>
          <p>
            <strong>INCENTIVE</strong>
          </p>
          <p>
            The incentive will be paid by Cheque/RTGS/NEFT only. The
            earning/incentive is on mobile subscription or product package sale
            only. The incentive/gifts due to &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s will be sent to his /her mailing address
            only. Annual processing charges and registration/licenses will be
            deducted from the incentive. Applicable TDS, Service Charges and
            Sponsor Bonus will be deducted from the incentive.
          </p>
          <p>
            <strong>
              PROMISSING BY RECRUITING &ldquo;MY STATUS&rdquo;
              DISTRIBUTORS/AGENTS
            </strong>
          </p>
          <p>
            &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s are not authorized
            to make any promise to prospect. If applicant relies on any promise
            made by &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s trying
            recruit him/her is not this agreement and or official material and
            that recruiting &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s
            fails to keep any such promise the applicant only have recourses
            such &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s and not the
            company. The &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s should
            understand that under any circumstances company is not responsible
            for any unauthorized acts/Omission committed by him. The &ldquo;MY
            STATUS&rdquo; Associates/MSBO&rsquo;s is responsible to indemnify
            the company for his/her unauthorized act he/she will be liable for
            all costs and consequences or recording to law.
          </p>
          <p>
            <strong>PRODUCT /SERVICE</strong>
          </p>
          <p>
            Amount of mobile app subscription, product package or registration
            processing charges includes cost of mobile app, product and personal
            development training plus promotional tools/material excludes
            licensee fees. Delivery of product is subject to availability of
            product. Product will be delivered within 21 days after company
            receives full payment towards product package. Company has sole
            right to change product package services or registration processing
            charges without prior notice. Though the care has been taken by the
            company while selecting the product which the company intends To
            market. &ldquo;No claims&rdquo; whatsoever shall be entertained by
            the company in case if any problem incurred by the user such claims
            would be only director to its respective manufacturer. In no event
            shall the company be liable for any claims or damages of any kind
            arising from using the product.
          </p>
          <p>
            <strong>RIGHTS</strong>
          </p>
          <p>
            The sale of mobile app subscription or product/s package of the
            company, does not create or constitute a joint or collaborative
            venture or partnership of any kind between the company and a
            &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s nor shall be
            construed or creating any such join or collaborate venture of
            partnership between the company and a &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s.
          </p>
          <p>
            <strong>NOMINATION&nbsp;</strong>
          </p>
          <p>
            In case of death of registered &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s, the benefit and representation will be
            transferred in favor of the nominee as mentions in the application
            form. The nominee shall be bound by the terms and conditions, rules
            and regulations of this agreement of the company.
          </p>
          <p>
            <strong>RIGHT OF THE COMPANY</strong>
          </p>
          <p>
            The company reserved the whole right to change rectify and to amend
            the incentive structure or gifts skim, cancel and intrudes rule and
            regulation, policies and procedures, term and condition from time to
            time and &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s shall abide
            by the same .
          </p>
          <p>
            <strong>JURISDICATION</strong>
          </p>
          <p>
            Any dispute arising from this agreement or in relation to be
            agreement shall be referred to a duly appointment arbitrate by the
            company. &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s should not
            raise any objection on the ground that the arbitrator is having any
            connection with the company.
          </p>
          <p>
            <strong>RENEWAL&nbsp;</strong>
          </p>
          <p>
            The representation is offer by the company is for one year only.
            Each and every &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s has
            to renewal for representation after one year from the date of
            joining. INR 1,111/- Paid by the &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s either same amount will be deducted yearly
            from the incentive of &ldquo;MY STATUS&rdquo;
            Associates/MSBO&rsquo;s as auto renewal .
          </p>
          <p>
            <strong>CONTENTS</strong>
          </p>
          <p>
            1) The company will not be screening the contents provided by the
            user/associate.
            <br /> 2) Any material that is threatening, abusive defamatory
            absence or otherwise un-law full.
            <br /> 3) Any material that violates copy right tread marks, patents
            or properties write.
          </p>
          <p>
            <strong>MESCELLANEOUS</strong>
          </p>
          <p>
            The signature in the application form will be taken as the &ldquo;MY
            STATUS&rdquo; Associates/MSBO&rsquo;s specimen signature. The
            company will not be responsible in case of communication/payment is
            not received by the &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s
            due to incomplete has given by him/her.
          </p>
          <p>
            <strong>REFERRAL BUSINESS TERMINATION</strong>
          </p>
          <ol>
            <li>
              IF a &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s withdraw
              their any referral bonus/gift/award/incentive etc.
            </li>
            <li>
              IF a &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s acts against
              the interest of the company.
            </li>
            <li>
              IF a &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s violates any
              of the terms and condition agreed here to or violated any
              subsequent change if made to the terms by the company.
            </li>
            <li>
              IF found any statement or information made in the application to
              the false.
            </li>
            <li>
              IF any &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s uses the
              company permeations/venues to represent any other company or its
              personal use.
            </li>
            <li>
              THE rights of a terminated &ldquo;MY STATUS&rdquo;
              Associates/MSBO&rsquo;s to received benefit (incentive /
              commission / compensations in whatever form) from the company
              ceases immediately from the date of termination.
            </li>
            <li>
              &ldquo;MY STATUS&rdquo; Associates/MSBO&rsquo;s who invites
              prospect (potential &ldquo;MY STATUS&rdquo;
              Associates/MSBO&rsquo;s or business presentation as the right to
              refer him/her if any other &ldquo;MY STATUS&rdquo;
              Associates/MSBO&rsquo;s tries to cease the prospect will be
              terminated)
            </li>
          </ol>
          <br />
          <p>
            Updated and effective from 26th Jan 2023.
            <br /> Copyright ©2023, MY STATUS. <br />
            All rights reserved.
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          {/* <p>
            {" "}
            <h1 className="heading">
              {" "}
              Version <br /> 1.0.0{" "}
            </h1>{" "}
            <h1 className="heading">
              {" "}
              AUTHOR{" "}
              <span>
                {" "}
                <p>Team My Status</p>{" "}
              </span>{" "}
            </h1>{" "}
            <h1 className="heading">
              {" "}
              Developed by{" "}
              <span>
                {" "}
                <p>My Software Solution</p>{" "}
              </span>{" "}
            </h1>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We’re client-focused application designers{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              Our master group of application designers utilizes more
              intelligent and friendlier routes for our customers and their
              clients to draw in with innovation, making remarkable client
              encounters.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We don’t make do with OK, we consistently make progress toward
              magnificence. It’s this enthusiasm that enables our customers to
              change transformation rates, enhance mark notorieties, win grants
              and remain on top of things, after quite a long time.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We pride ourselves on building long-haul associations with our
              customers, attempting to enable them to accomplish their business
              objectives. You’ll work specifically with the application
              engineers themselves, auditing and teaming up with them on each
              phase of the procedure.{" "}
            </p>{" "}
            <h1 className="heading"> .</h1>{" "}
            <h1 className="heading">HOW DO WE DO IT?</h1>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We help control customers through the procedures of building an
              application, from starting a plan to supporting the last
              organization.{" "}
            </p>{" "}
            <h1 className="heading">Pre-Design</h1>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We break down the prerequisites of the venture so as to prescribe
              the best way to deal with meet both business and client
              objectives. We don’t just take briefs; we challenge any
              pre-characterized usefulness or choices and talk about choices
              using our profound versatile information.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              Brushing your industry information with our versatile innovation
              skills.{" "}
            </p>{" "}
            <h1 className="heading">Plan</h1>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We draw on our broad experience, got from planning more than 250
              versatile arrangements, so as to enable customers to structure a
              five-star client encounter.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              Our creative plans will guarantee that the UX of your application
              has your end client at the cutting edge of each choice. With
              client testing and criticism at all times, ready to plan the ideal
              answer for your intended interest group.{" "}
            </p>{" "}
            <h1 className="heading">Improvement</h1>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              Following the light-footed technique, we can do nonstop discharges
              to give you full perceivability of the venture as it advances. Our
              communitarian approach will offer you the chance to criticize
              toward the finish of each run.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              Working inside test-driven conditions implies we can guarantee
              well-performing and stable forms all through improvement.{" "}
            </p>{" "}
            <h1 className="heading">24X7 Support</h1>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We are truly available 24X7 to support our clients When an
              application is propelled, we screen it intimately with our
              in-constructed investigation to keep up and enhance the item as we
              travel through the future stages.{" "}
            </p>{" "}
            <p style={{ marginBottom: "20px" }}>
              {" "}
              We pride ourselves on proactively breaking down the information,
              which enables us to prescribe extra usefulness or changes,
              inserting the application inside the intended interest group, and
              increasing significant direct criticism.{" "}
            </p>{" "}
          </p>{" "} */}
        </div>{" "}
      </div>{" "}
      <div className="ugcFooter">
        {" "}
        <Footer />{" "}
      </div>{" "}
    </div>
    // <div className="referralFullContainer">
    //   <Topbar />
    //   <Header />
    //   <div className="refferalContainer">
    //     <h1>REFERRAL BUSINESS POLICY</h1>
    //     <p>
    //       REFERRAL BUSINESS POLICY as mentioned below constitute an agreement
    //       between “MY STATUS” (here in after referred to as Company) and its
    //       Associates/MSBO’s (My Status Business Owners) (here in after referred
    //       to as “MY STATUS” distributors/agents one who duly purchased the
    //       mobile app, products, package of the company and completes all the
    //       formalities laid down by the company.)
    //     </p>
    //     <p>
    //       This agreement does not create an employee-employer relationship,
    //       partnership or joint venture between “MY STATUS” Associates/MSBO’s and
    //       Company. The “MY STATUS” Associates/MSBO’s must be of legal age 18
    //       years as on the date of application/registration. The “MY STATUS”
    //       Associates/MSBO’s entering into this agreement with his free will and
    //       consent. Company does not promise any kind of monitory gain in any way
    //       to “MY STATUS” Associates/MSBO’s. Earning Commission or Get Gifts from
    //       “MY STATUS”, Associates/MSBO’s will be solely depend on his/her best
    //       performance. All the “MY STATUS” Associates/MSBO’s will use his/her
    //       best effort to permute mobile app subscription, product package and
    //       services of the company truthful and honest manner. The “MY STATUS”
    //       Associates/MSBO’s should not use trademarked symbol literature and
    //       slogans of the company for any other purpose. Other then as specified
    //       by the company. The company will deduct tax at source as per income
    //       tax act 1961. As well as any other Govt. taxes as applicable time to
    //       time/duties/charges as the prevailing rate, an annual processing
    //       registration and Service charges etc.
    //     </p>
    //     <h4>REGISTRATION</h4>
    //     <p>
    //       The “MY STATUS” mobile app subscription, product package sale or
    //       referral bonus for to people residing in India and for Indian
    //       nationals only. The Registration of “MY STATUS” Associates/MSBO’s
    //       becomes ACTIVE USER effective from the date of acceptance of agreement
    //       valid up to one year against Full payment of the subscription/purchase
    //       of product package to the company.{" "}
    //       <span style={{ fontWeight: "bold" }}>
    //         (Note : Registration for REFERRAL BUSINESS is free for all users)
    //       </span>
    //     </p>
    //     <h4>FEES</h4>
    //     <p>
    //       The amount of purchase of mobile subscription, product package or
    //       registration processing charge of company and rights to become “MY
    //       STATUS” Associates/MSBO’s of the company. The amount paid towards
    //       company for the purchase of the mobile subscription, product package
    //       or registration processing charge is non refundable and non
    //       transferable. Courier/product delivery charges/octopi/local taxes any
    //       other Govt. taxes applicable time to time should strictly be born by
    //       “MY STATUS” Associates/MSBO’s only. Monthly statement or any business
    //       related document of “MY STATUS” Associate’s, incentive, cheque, gifts
    //       will be made chartable basis which includes the actual cost of
    //       communication and delivery charges.
    //     </p>
    //     <h4>INCENTIVE</h4>
    //     <p>
    //       The incentive will be paid by Cheque/RTGS/NEFT only. The
    //       earning/incentive is on mobile subscription or product package sale
    //       only. The incentive/gifts due to “MY STATUS” Associates/MSBO’s will be
    //       sent to his /her mailing address only. Annual processing charges and
    //       registration/licenses will be deducted from the incentive. Applicable
    //       TDS, Service Charges and Sponsor Bonus will be deducted from the
    //       incentive.
    //     </p>
    //     <h4>PROMISSING BY RECRUITING “MY STATUS” DISTRIBUTORS/AGENTS</h4>
    //     <p>
    //       “MY STATUS” Associates/MSBO’s are not authorized to make any promise
    //       to prospect. If applicant relies on any promise made by “MY STATUS”
    //       Associates/MSBO’s trying recruit him/her is not this agreement and or
    //       official material and that recruiting “MY STATUS” Associates/MSBO’s
    //       fails to keep any such promise the applicant only have recourses such
    //       “MY STATUS” Associates/MSBO’s and not the company. The “MY STATUS”
    //       Associates/MSBO’s should understand that under any circumstances
    //       company is not responsible for any unauthorized acts/Omission
    //       committed by him. The “MY STATUS” Associates/MSBO’s is responsible to
    //       indemnify the company for his/her unauthorized act he/she will be
    //       liable for all costs and consequences or recording to law.
    //     </p>
    //     <h4>PRODUCT /SERVICE</h4>
    //     <p>
    //       Amount of mobile app subscription, product package or registration
    //       processing charges includes cost of mobile app, product and personal
    //       development training plus promotional tools/material excludes licensee
    //       fees. Delivery of product is subject to availability of product.
    //       Product will be delivered within 21 days after company receives full
    //       payment towards product package. Company has sole right to change
    //       product package services or registration processing charges without
    //       prior notice. Though the care has been taken by the company while
    //       selecting the product which the company intends To market. “No claims”
    //       whatsoever shall be entertained by the company in case if any problem
    //       incurred by the user such claims would be only director to its
    //       respective manufacturer. In no event shall the company be liable for
    //       any claims or damages of any kind arising from using the product.
    //     </p>
    //     <h4>RIGHTS</h4>
    //     <p>
    //       The sale of mobile app subscription or product/s package of the
    //       company, does not create or constitute a joint or collaborative
    //       venture or partnership of any kind between the company and a “MY
    //       STATUS” Associates/MSBO’s nor shall be construed or creating any such
    //       join or collaborate venture of partnership between the company and a
    //       “MY STATUS” Associates/MSBO’s.
    //     </p>
    //     <h4>NOMINATION </h4>
    //     <p>
    //       In case of death of registered “MY STATUS” Associates/MSBO’s, the
    //       benefit and representation will be transferred in favor of the nominee
    //       as mentions in the application form. The nominee shall be bound by the
    //       terms and conditions, rules and regulations of this agreement of the
    //       company.
    //     </p>
    //     <h4>RIGHT OF THE COMPANY</h4>
    //     <p>
    //       The company reserved the whole right to change rectify and to amend
    //       the incentive structure or gifts skim, cancel and intrudes rule and
    //       regulation, policies and procedures, term and condition from time to
    //       time and “MY STATUS” Associates/MSBO’s shall abide by the same .
    //     </p>
    //     <h4>JURISDICATION</h4>
    //     <p>
    //       Any dispute arising from this agreement or in relation to be agreement
    //       shall be referred to a duly appointment arbitrate by the company. “MY
    //       STATUS” Associates/MSBO’s should not raise any objection on the ground
    //       that the arbitrator is having any connection with the company.
    //     </p>
    //     <h4>RENEWAL </h4>
    //     <p>
    //       The representation is offer by the company is for one year only. Each
    //       and every “MY STATUS” Associates/MSBO’s has to renewal for
    //       representation after one year from the date of joining. INR 1,111/-
    //       Paid by the “MY STATUS” Associates/MSBO’s either same amount will be
    //       deducted yearly from the incentive of “MY STATUS” Associates/MSBO’s as
    //       auto renewal .
    //     </p>
    //     <h4>CONTENTS</h4>
    //     <p>
    //       1) The company will not be screening the contents provided by the
    //       user/associate.
    //       <br /> 2) Any material that is threatening, abusive defamatory absence
    //       or otherwise un-law full.
    //       <br /> 3) Any material that violates copy right tread marks, patents
    //       or properties write.
    //     </p>
    //     <h4>MESCELLANEOUS</h4>
    //     <p>
    //       The signature in the application form will be taken as the “MY STATUS”
    //       Associates/MSBO’s specimen signature. The company will not be
    //       responsible in case of communication/payment is not received by the
    //       “MY STATUS” Associates/MSBO’s due to incomplete has given by him/her.
    //     </p>
    //     <h4>REFERRAL BUSINESS TERMINATION</h4>
    //     <p>
    //       1) IF a “MY STATUS” Associates/MSBO’s withdraw their any referral
    //       bonus/gift/award/incentive etc. <br />
    //       2) IF a “MY STATUS” Associates/MSBO’s acts against the interest of the
    //       company.
    //       <br /> 3) IF a “MY STATUS” Associates/MSBO’s violates any of the terms
    //       and condition agreed here to or violated any subsequent change if made
    //       to the terms by the company.
    //       <br />
    //       4) IF found any statement or information made in the application to
    //       the false.
    //       <br /> 5) IF any “MY STATUS” Associates/MSBO’s uses the company
    //       permeations/venues to represent any other company or its personal use.
    //       <br />
    //       6) THE rights of a terminated “MY STATUS” Associates/MSBO’s to
    //       received benefit (incentive / commission / compensations in whatever
    //       form) from the company ceases immediately from the date of
    //       termination.
    //       <br /> 7) “MY STATUS” Associates/MSBO’s who invites prospect
    //       (potential “MY STATUS” Associates/MSBO’s or business presentation as
    //       the right to refer him/her if any other “MY STATUS” Associates/MSBO’s
    //       tries to cease the prospect will be terminated)
    //     </p>
    //   </div>
    // </div>
  );
};

export default Refferal;
