import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Topbar from "../Topbar/Topbar";
import TermsUI from "../UI/TermsUI";
import "./Cookies.css";
const Cookies = () => {
  return (
    <div className="commonContainer">
      {" "}
      <Topbar /> <TermsUI text={"COOKIE POLICY"} />{" "}
      <div className="container_lvl_1 terms">
        <div>
          <p>
            <br />
          </p>
          <p>
            <strong>What are cookies?</strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            Cookies are small text files containing a string of characters that
            can be placed on your computer or mobile device that uniquely
            identifies your browser or device. We may use technologies like
            cookies, pixels, and local storage to deliver, secure, and
            understand products, services, and ads.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>What are cookies used for?</strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            Cookies and other technologies allow a site or services to know if
            your computer or device has visited it before. These technologies
            can then be used to deliver products, services, and ads, help us
            understand how the site or service is being used, help you navigate
            between pages efficiently, help us remember your preferences, and
            generally improve your experience in using our services. Cookies can
            also help ensure marketing you see online is more relevant to you
            and your interests.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              What will happen If you turn off cookies completely?
            </strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            Overall, cookies are safe, as they only identify your computer and
            mobile to customize your Web and App experience. Accepting a cookie
            does not provide us access to your computer, mobile or any
            Personally Identifiable Information about you, other than the
            information you choose to share. Other servers cannot read them, nor
            can they be used to deliver a virus.
          </p>
          <p>
            Most browsers automatically accept cookies, but you can usually
            adjust yours (i.e. Microsoft Internet Explorer, Firefox, Safari, or
            Google Chrome) to notify you of cookie placement requests, refuse
            certain cookies, or decline cookies completely. If you turn off
            cookies completely, there may be some features of the Site and App
            that will not be available to you, and some Web pages and App pages
            may not display properly.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              Why does MY STATUS use cookies and similar technologies?
            </strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            We use cookies, pixels, local storage, and similar technologies to
            show you relevant content, improve your experience, and help protect
            MY STATUS and our users. We may use these technologies to deliver
            the service, provide you with a service that is easy to use, enable
            you to move around the Service and use its features, such as
            accessing secure areas, and store information so that MY STATUS
            responds faster. We may use these technologies to collect
            information about how you use the Service, for example which pages
            you go to most often and whether you get error messages from certain
            pages. We may use these technologies to allow us to remember choices
            you make (such as your user name, language or the region you&apos;re
            in) and tailor the Service to provide enhanced features and content
            for you. These cookies can also be used to remember changes
            you&apos;ve made to text size, font, and other parts of pages that
            you can customize. MY STATUS or our advertising partners may use
            these technologies to deliver advertising that is relevant to your
            interests. These technologies can remember that your device has
            visited a site or service, and may also be able to track your
            device&apos;s browsing activity on other sites or services other
            than MY STATUS. This information may be shared with organizations
            outside MY STATUS, such as advertisers and/or advertising networks
            to deliver the advertising, and to help measure the effectiveness of
            an advertising campaign. *We may use these technologies to
            understand, improve, and research products and services.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>How long will cookies stay on my device?</strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            The length of time a cookie will stay on your computer or mobile
            device depends on whether it is a &quot;persistent&quot; or
            &quot;session&quot; cookie. Session cookies will only stay on your
            device until you close your browser. Persistent cookies stay on your
            computer or mobile device until they expire or are deleted.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>First- and third-party cookies</strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            First-party cookies are cookies that belong to MY STATUS.
            Third-party cookies are cookies that another party places on your
            device through our Service. Third-party cookies may be placed on
            your device by someone providing advertising, measurement,
            marketing, and analytics services to MY STATUS, or to provide
            certain features and improve our services for you. For example,
            other companies&rsquo; cookies can help us understand how our
            service is being used. Third-party cookies may also be placed on
            your device by our business partners so that they can use them to
            advertise products and services to you elsewhere on the Internet, to
            measure the performance and effectiveness of those ads, and to
            support marketing and analytics.
          </p>
          <p>
            <br />
          </p>
          <p>
            Updated and effective from 26th Jan 2023.
            <br /> Copyright ©2023, MY STATUS. <br />
            All rights reserved.
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </div>{" "}
      </div>{" "}
      <div className="ugcFooter">
        <Footer />{" "}
      </div>{" "}
    </div>
    // <div className="cookiesFullContainer">
    //   <Topbar />
    //   <Header />
    //   <div className="cookiesContainer">
    //     <h1>COOKIES POLICY</h1>
    //     <h4>About Cookies</h4>
    //     <h4>What are cookies?</h4>
    //     <p>
    //       Cookies are small text files containing a string of characters that
    //       can be placed on your computer or mobile device that uniquely
    //       identifies your browser or device. We may use technologies like
    //       cookies, pixels, and local storage to deliver, secure, and understand
    //       products, services, and ads.
    //     </p>
    //     <h4>What are cookies used for?</h4>
    //     <p>
    //       Cookies and other technologies allow a site or services to know if
    //       your computer or device has visited it before. These technologies can
    //       then be used to deliver products, services, and ads, help us
    //       understand how the site or service is being used, help you navigate
    //       between pages efficiently, help us remember your preferences, and
    //       generally improve your experience in using our services. Cookies can
    //       also help ensure marketing you see online is more relevant to you and
    //       your interests.
    //     </p>
    //     <h4>What will happen If you turn off cookies completely?</h4>
    //     <p>
    //       Overall, cookies are safe, as they only identify your computer and
    //       mobile to customize your Web and App experience. Accepting a cookie
    //       does not provide us access to your computer, mobile or any Personally
    //       Identifiable Information about you, other than the information you
    //       choose to share. Other servers cannot read them, nor can they be used
    //       to deliver a virus.
    //     </p>
    //     <p>
    //       Most browsers automatically accept cookies, but you can usually adjust
    //       yours (i.e. Microsoft Internet Explorer, Firefox, Safari, or Google
    //       Chrome) to notify you of cookie placement requests, refuse certain
    //       cookies, or decline cookies completely. If you turn off cookies
    //       completely, there may be some features of the Site and App that will
    //       not be available to you, and some Web pages and App pages may not
    //       display properly.
    //     </p>
    //     <h4>Why does MY STATUS use cookies and similar technologies?</h4>
    //     <p>
    //       We use cookies, pixels, local storage, and similar technologies to
    //       show you relevant content, improve your experience, and help protect
    //       MY STATUS and our users. We may use these technologies to deliver the
    //       service, provide you with a service that is easy to use, enable you to
    //       move around the Service and use its features, such as accessing secure
    //       areas, and store information so that MY STATUS responds faster. We may
    //       use these technologies to collect information about how you use the
    //       Service, for example which pages you go to most often and whether you
    //       get error messages from certain pages. We may use these technologies
    //       to allow us to remember choices you make (such as your user name,
    //       language or the region you're in) and tailor the Service to provide
    //       enhanced features and content for you. These cookies can also be used
    //       to remember changes you've made to text size, font, and other parts of
    //       pages that you can customize. MY STATUS or our advertising partners
    //       may use these technologies to deliver advertising that is relevant to
    //       your interests. These technologies can remember that your device has
    //       visited a site or service, and may also be able to track your device's
    //       browsing activity on other sites or services other than MY STATUS.
    //       This information may be shared with organizations outside MY STATUS,
    //       such as advertisers and/or advertising networks to deliver the
    //       advertising, and to help measure the effectiveness of an advertising
    //       campaign. *We may use these technologies to understand, improve, and
    //       research products and services.
    //     </p>
    //     <h4>How long will cookies stay on my device?</h4>
    //     <p>
    //       The length of time a cookie will stay on your computer or mobile
    //       device depends on whether it is a "persistent" or "session" cookie.
    //       Session cookies will only stay on your device until you close your
    //       browser. Persistent cookies stay on your computer or mobile device
    //       until they expire or are deleted.
    //     </p>
    //     <h4>First- and third-party cookies</h4>
    //     <p>
    //       First-party cookies are cookies that belong to MY STATUS. Third-party
    //       cookies are cookies that another party places on your device through
    //       our Service. Third-party cookies may be placed on your device by
    //       someone providing advertising, measurement, marketing, and analytics
    //       services to MY STATUS, or to provide certain features and improve our
    //       services for you. For example, other companies’ cookies can help us
    //       understand how our service is being used. Third-party cookies may also
    //       be placed on your device by our business partners so that they can use
    //       them to advertise products and services to you elsewhere on the
    //       Internet, to measure the performance and effectiveness of those ads,
    //       and to support marketing and analytics.
    //     </p>
    //     <p>
    //       Updated and effective from 26th Jan 2023. ©2023 MY STATUS, all rights
    //       reserve
    //     </p>
    //   </div>
    // </div>
  );
};

export default Cookies;
